
import React, {useRef, useEffect} from "react";
import {useAsyncSetState} from "use-async-setstate";
import Formsy from "formsy-react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {fetch} from "window-or-global";
import fetchin from "../logic/utils/fetch";
import Manager from "../components/manager";
import Theme from "../components/theme";
import Button from "../components/bootstrap/button";
import FormsyTextField from "../components/formsy/text-field";
import config from "../config";
import Terms from "../components/tnc";
import root from "window-or-global";

const str = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}
export function generatePassword(s = str, len = 8) {
  let r = "";
  for (let i = 0; i < len; i++) {
    const idx = Math.round(getRandomArbitrary(0, s.length - 1));
    r += s[idx];
  }
  return r;
}


export default function LoginPage() {
  const [errorMessage, setErrorMessage] = useAsyncSetState(null);
  const [checked, setCheck] = useAsyncSetState(false);
  const [showTerm, setTerm] = useAsyncSetState(false);
  const [loading, setLoadingAsync] = useAsyncSetState(false);
  const authFormEl = useRef(null);
  const [creds, setCreds] = useAsyncSetState({
    username: "",
    password: generatePassword(),
    dst: ""
  });
  const [authUrl, setAuthUrl] = useAsyncSetState(config.authUrl);
  useEffect(() => {
    try {
      if (root.location) {
        const url = new URL(root?.location);
        if (url.searchParams.has("src")) {
          const srcLocation = atob(url.searchParams.get("src"));
          const srcUri = new URL(srcLocation);
          setAuthUrl(`https://${srcUri.host}/login`)
          console.log("custom target set", `https://${srcUri.host}/login`);
        }
      }
    } catch(err) {
      console.log("failed to set custom auth url", err);
    }
  }, []);

  
  async function checkIfLoggedInOnHotspot() {
    try {
      const text = await fetchin.get(config.loginStatus);
      console.log("data", text);
      const data = JSON.parse(text);
      if (data) {
        if (data.loggedIn === "yes") {
          root.location = config.targetUrl;
        }
      }
    } catch(err) {
      console.log("unable to talk to the hotspot", err);
    }
  }
  
  async function handleValidSubmit(data) {
    try {
      await setLoadingAsync(true);
      const {residency, ...rest} = data;
      const response = await fetch(`${config.apiUrl}rest.api/register`, {
        credentials: "include",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign({password: creds.password}, {
          ...data, 
        })),
      });
      switch(response.status) {
        case 503:
        case 401:
          await setErrorMessage(() => (<>
            {"An error has occurred"}
          </>));
        case 200:
          const result = await response.json();
          // console.log("results", results)
          const {userName} = result;

          setCreds({...creds, userName});
          authFormEl.current.submit();
          // submit
      }
    } catch (err) {
      console.log("err", err);
      setErrorMessage("An error has occurred");
    }
  }
  
  
  useEffect(() => {
    checkIfLoggedInOnHotspot();
  }, []);
  
  return (
    <Manager name="login" title="Register">
      <Theme>
        <form ref={authFormEl} action={authUrl} method="post">
          <input type="hidden" name="username" value={creds.userName} />
          <input type="hidden" name="password" value={creds.password} />
          <input type="hidden" name="dst" value={"https://events.sunshinecoast.qld.gov.au/"} />
        </form>
        <Formsy onValidSubmit={(data) => handleValidSubmit(data)}>
          <Container fluid className="form-spacer no-gutters">
            <Row>
              <Col>
                <h2>{"Please enter your details below"}</h2>
              </Col>
            </Row>
            <Row className="field-row">
              <Col>
                <FormsyTextField
                  name={"firstName"}
                  label="First Name"
                  required
                />
              </Col>
            </Row>

            <Row className="field-row">
              <Col>
                <FormsyTextField
                  name={"lastName"}
                  label="Last Name"
                  required
                />
              </Col>
            </Row>
            <Row className="field-row">
              <Col>
                <FormsyTextField
                  name={"email"}
                  type="email"
                  label="Email Address"
                  validations={"isEmail"}
                  validationError="A valid email address is required"
                  required
                />
              </Col>
            </Row>
            <Row className="field-row">
              <Col>
                {showTerm && <Terms onClose={() => setTerm(false)} />}
                <div className="vw-checkbox">
                  <i
                    onClick={async () => setCheck(!checked)}
                    style={{ fontSize: 36 }}
                    className={`mr-2 ${
                      checked ? "far fa-check-square" : "far fa-square"
                    }`}
                  />
                  {"I agree to the"}
                  <a onClick={() => setTerm(true)} className="mx-1">
                    {"terms of use"}
                  </a>
                  {" of this network."}
                  {/* {" and "}
                  <a onClick={() => setPolicy(true)} className="mx-1">
                    {"Privacy Policy"}
                  </a> */}
                </div>
              </Col>
            </Row>
            {errorMessage && (<Row>
              <Col>
                <div className="alert alert-danger">
                  {errorMessage}
                </div>
              </Col>
            </Row>)}
            <Row>
              <Col xs={12}>
                <Button
                  type={"submit"}
                  style={{width: "100%"}}
                  className="w-100 d-block mx-auto"
                  disabled={!checked}
                  colour="primary">
                  {"Continue"}
                </Button>
              </Col>
            </Row>
          </Container>
        </Formsy>
      </Theme>
    </Manager>
  );
}
