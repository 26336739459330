import React from "react"
import { Col, Container, Row, Button } from "react-bootstrap"

import Modal from "./bootstrap/modal"

export default function TermModal(props) {
  const { onClose } = props

  return (
    <Modal
      title={"Terms of Use"}
      onClose={onClose}
      show
      footer={
        <Row>
          <Col xs="auto" className="ml-auto"></Col>
          <Col xs="auto">
            <Button variant="darkblue" type="button" onClick={() => onClose()}>
              {"Close"}
            </Button>
          </Col>
        </Row>
      }
    >
      <Container fluid className="terms">
        <Row>
          <Col>
            <h2>
              <strong>Sunshine Coast Free Wi-Fi Terms of Use</strong>
            </h2>
            <p>
              Welcome to Sunshine Coast Free Wi-Fi ("Service") arranged by Sunshine Coast Council ("Council") through its partner VostroNet (Australia) Pty Ltd (“Provider”)..
            </p>
            <p>
              Access to the Service is subject to these terms.  Council may modify these terms at any time. You should review these terms each time you access the Service. 
            </p>
            <p>
              By accepting these terms and accessing and using the Service, you: 
            </p>

            <ul>
              <li>
                acknowledge that you are at least 18 years of age or have obtained consent from your parent or legal guardian to use the Service;
              </li>
              <li>
                agree to be bound by these terms; and
              </li>
              <li>
                assume all responsibility and risk arising out of or in connection with your use of the Service.
              </li>
            </ul>
            <p>
              <b>
                If you do not agree with these terms, you must not access or use the Service. 
              </b>
            </p>
            <p>
              The Provider provides access to the internet through the Service on behalf of Council. In these terms, all references to Council will be taken to include the Provider.
            </p>
            <h2>
              <strong>Use of the Service </strong>
            </h2>
            <ol>
              <li>
                The Service is available to your wireless-enabled device/s only when it is within the range of the Provider’s wireless local access network. You are responsible for providing your own wireless-enabled device capable of accessing the Service with its own power source.
              </li>
              <li>
                Session times and download limits may apply to your use of the Service. These limits will be determined by Council in its absolute discretion.  You may be logged-out of the Service if you exceed these limits.
              </li>
              <li>
                Council may prevent the Service from accessing websites or other material that Council determines to be offensive or otherwise inappropriate for a public Wi-Fi service.  This does not affect your responsibility to comply with the obligations below. 
              </li>
              <li>
                When you are using the Service, you must not: 
                <ol type="a">
                  <li>
                    breach any applicable laws;
                  </li>
                  <li>
                    infringe the intellectual property rights (including copyright) of any person.
                  </li>
                  <li>
                    interfere with the normal operation of the Service or equipment used to provide the Service;
                  </li>
                  <li>
                    collect or harvest information or data from the Service or systems or attempt to decipher any transmissions to or from the servers providing the Service;
                  </li>
                  <li>
                    allow another person to access or use the Service indirectly through your device; 
                  </li>
                  <li>
                    use the Service for a commercial or business purpose;
                  </li>
                  <li>
                    sell or re-sell the Service;
                  </li>
                  <li>
                    expose any minor to material that is unsuitable for minors;
                  </li>
                  <li>
                    use the Service to transmit, store, publish or upload any electronic material which is known or is likely to cause, damage or destroy or limit the functionality of any computer software, hardware or telecommunications equipment;
                  </li>
                  <li>
                    cause Council to breach any law or incur liability to a third party; or
                  </li>
                  <li>
                    use the Service for any criminal, unlawful, defamatory, threatening, sexually explicit or pornographic, violent, abusive, harmful, or obscene purpose, and must not otherwise be a nuisance, spread or incite hatred, or breach privacy. 
                  </li>
                </ol>
              </li>
              <li>
                The Service is provided on an “as is” basis and Council does not warrant that the Service will be uninterrupted, fault-free, secure, accessible at all times or fit for any particular purpose. Council has no obligation to restore faults in the Service, or to provide power, advice or assistance. The Service is not suitable for supporting any applications or for use which require continuous, fault-free network connectivity or uninterrupted data traffic flow.
              </li>
              <li>
                You are responsible for the security, privacy and backing up of your software and applications, and any information you transmit using the Service.
              </li>
              <li>
                You are responsible for all consents, royalties and fees related to third party material accessed through the Service.
              </li>
              <li>
                The Service may be suspended, withdrawn or changed, or your access blocked or restricted, at any time for any reason and without notice. You may also be logged out of the Service without notice at any time. 
              </li>
            </ol>
            <h2>
              <strong>
                Information about you and your use of the Service
              </strong>
            </h2>
            <ol>
              <li>
                Council may require you to provide personal information, such as your name and email address, before you can use the Service.  This information is used for the purpose of providing you with access to the Service.
              </li>
              <li>
                Council may collect information about your use of the Service.  This may include information about the device, operating system and type of web browser you use to access the Service, the time and duration of such use and the URL or IP address of websites you visit.  This information is used to monitor compliance with these terms and to manage and improve the Service.
              </li>
              <li>
                Any personal information you provide or Council collects in connection with the Service will be handled in accordance with Council's privacy policy, which is available on Council's website.
              </li>
            </ol>
            <h2>
              <strong>
                Council Liability
              </strong>
            </h2>
            <ol>
              <li>
                Council does not warrant or represent that any of the data, content or information you access through the Service is accurate, complete, secure or fit for any particular purpose. Council does not control the data, content or information you access through the Service and disclaims all liability in relation to such data, content and information.
              </li>
              <li>
                Your use of the Service is at your own risk. To the extent permitted by law, Council excludes:
                <ol type="a">
                  <li>
                    all liability to you for any negligent acts or omissions by Council, its employees, agents and contractors arising out of or in connection with the Service; 
                  </li>
                  <li>
                    all liability to you for any loss of profits, loss of revenue, loss or corruption of data, loss of any contract value, loss of anticipated profit, damages for lost opportunity or any other consequential or indirect loss arising from or in connection with your use of the Service; and
                  </li>
                  <li>
                    any conditions, warranties and terms implied by statute, common law or custom.
                  </li>
                </ol>
              </li>
              <li>
                To the extent permitted by law, Council’s maximum liability to you for breach of these terms, or otherwise, is limited to resupplying the Service.
              </li>
              <li>
                You indemnify Council from and against any loss, cost or liability Council may suffer or incur as a result of:
                <ol type="a">
                  <li>
                    your use of the Service, including any data, content or information you access, make available or receive using the Service; or
                  </li>
                  <li>
                    any breach by you of any of these terms, including use of the Service by you or permitted by you.
                  </li>
                </ol>
              </li>
            </ol>
          </Col>
        </Row>
      </Container>
    </Modal>
  )
}
